import React, {useRef, useState} from 'react';
import './App.css';
import html2canvas from "html2canvas";
import {useParams} from 'react-router-dom';
import styles from "./App.module.scss";
import ic_share from "./asserts/icons/ic_share.svg";
import ic_download from "./asserts/icons/ic_download.svg";
import ic_logo from "./asserts/cupower/ic_cupower.png";
import ic_official_site_qr_code from "./asserts/cupower/ic_official_site.png";
import ic_location from "./asserts/icons/ic_location.png";
import ic_company from "./asserts/cupower/ic_company.jpg"
import ic_phone from "./asserts/icons/ic_phone.png";
import ic_address from "./asserts/icons/ic_address.png";
import ic_email from "./asserts/icons/ic_email.png";
import ic_linkin from "./asserts/icons/ic_linkin.png";
import ic_wechat from "./asserts/icons/ic_wechat.png";
import ic_network from "./asserts/icons/ic_network.png";
import {CenterPopup} from "antd-mobile";
import ui_langs from "./ui_langs.json";
import UserList from "@/user_list";
import Canvas2Image from "@/canvas2Image";

type PersonalInfo = {
    UserName: string;
    Avatar: any,
    OfficeLocation: "EMEA" | "AMERICA" | "ASIA" | "UNKNOWN";
    OfficePositionName: string;
    PhoneAreaCode: string;
    PhoneNumber: string;
    EmailAddress: string;
    WeChatID: string;
    LinkInAccount: string;
    OfficialSiteAddress: string;
    CompanyAddress: string;
}

const defaultInfo: PersonalInfo = {
    UserName: "---",
    Avatar: ic_company,
    OfficeLocation: "UNKNOWN",
    OfficePositionName: "---",
    PhoneAreaCode: "+86",
    PhoneNumber: "---",
    EmailAddress: "---",
    WeChatID: "---",
    LinkInAccount: "---",
    OfficialSiteAddress: "www.cupower.com",
    CompanyAddress: "深圳市宝安区桃花源智创小镇E栋2楼"
}

type UITitles = {
    "phone_title": string,
    "email_title": string,
    "wechat_title": string,
    "linkin_title": string,
    "official_site_title": string,
    "address_title": string,
    "share_by_picture_title": string,
    "download_to_contact_title": string,
    "born_to_be_light_title": string
}

export const App = (props: any) => {
    const [personalInfoState, setPersonalInfoState] = useState<PersonalInfo>(defaultInfo)
    const contentIdRef = React.createRef<HTMLDivElement>()
    const [showLangSelectPopup, setShowLangSelectPopup] = useState(true)
    const [uiTitles, setUiTitles] = useState<UITitles>(ui_langs.ui.cn)

    const [isEnglish, setIsEnglish] = useState(false)
    const params = useParams()

    let currentUserId = params["userid"];

    const currentUserRef = useRef<PersonalInfo>();

    const shareByPicture = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        html2canvas(contentIdRef.current!, {allowTaint: false, scale: window.devicePixelRatio})
            .then(canvas => {
                Canvas2Image.saveAsPNG(canvas, undefined, undefined, `cupower_com_${currentUserId}_${isEnglish ? "English" : "Chinese"}`);
            });
    }//shareByPicture

    const saveData = (data: string, fileName: string) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        // blob = new Blob([json], {type: "octet/stream"}),
        var blob = new Blob([data], {type: "text/vcard"});
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const DownloadToPhone = () => {

        const contact = {
            name: currentUserRef.current!.UserName,
            phone: `${currentUserRef.current!.PhoneAreaCode}-${currentUserRef.current!.PhoneNumber}`,
            email: currentUserRef.current!.EmailAddress
        };

        // create a vcard file
        const vcard = "BEGIN:VCARD\nVERSION:4.0\nFN;CHARSET=UTF-8:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nEND:VCARD";

        saveData(vcard, `cupower_com_${currentUserRef.current!.UserName}.vcf`)
    }

    const ChooseChinese = () => {
        setIsEnglish(false)
        const userId = params["userid"]
        setUiTitles(ui_langs.ui.cn)
        UserList.list.forEach(item => {
            if (item.name == userId) {
                // User Info
                setPersonalInfoState(item.cn as PersonalInfo)
                currentUserRef.current = item.cn as PersonalInfo
                setShowLangSelectPopup(false)
            } else {
                console.log("not found")
            }
        })
    }//ChooseChinese

    const ChooseEnglish = () => {
        setIsEnglish(true)
        const userId = params["userid"]
        setUiTitles(ui_langs.ui.en)
        UserList.list.forEach(item => {
            if (item.name === userId) {
                // User Info
                setPersonalInfoState(item.en as PersonalInfo)
                currentUserRef.current = item.en as PersonalInfo
                setShowLangSelectPopup(false)
            } else {
                console.log("not found")
            }
        })
    }//ChooseEnglish

    return (
        <div className={styles.container}>
            {/*Content*/}
            <div id={"info_content_id"} ref={contentIdRef} className={styles.container_content}>
                {/*Header, Personal Avatar and Company Logo*/}
                <div className={styles.personal_avatar_info}>
                    {/*<object className={styles.avatar_img} type={"image/svg+xml"} data={ic_rita}/>*/}
                    <div className={styles.avatar_img}>
                        <img src={personalInfoState.Avatar}/>
                    </div>

                    <div className={styles.logo}>
                        {/*<object className={styles.img} type={"image/svg+xml"} data={ic_logo}/>*/}
                        <img className={styles.img} src={ic_logo}/>
                    </div>
                </div>

                {/* Name and Position Detail Information*/}
                <div className={styles.name_position_detail_info}>
                    <div className={styles.name_and_position_title}>
                        <div className={styles.names}>
                            <span className={styles.user_name}>{personalInfoState.UserName}</span>
                        </div>
                    </div>

                    <div className={styles.split_line}/>

                    <div className={styles.position_titles}>
                        <div className={styles.location}>
                            <img src={ic_location}/>
                            <span>{personalInfoState.OfficeLocation}</span>
                        </div>
                        <span className={styles.en_position_name}>
                        {personalInfoState.OfficePositionName}
                    </span>
                    </div>
                </div>

                {/* Splitter */}
                <div className={styles.center_splitter}/>

                {/* Personal Detail Info */}
                <div className={styles.contacts_detail_info}>

                    {/* Phone */}
                    <div className={styles.item_large}>
                        <div className={styles.text}>
                            <img src={ic_phone}/>
                            <span>{uiTitles.phone_title}</span>
                        </div>
                        <div className={styles.value}>
                            <a href={`tel://${personalInfoState.PhoneAreaCode + "-" + personalInfoState.PhoneNumber}`}>
                                {personalInfoState.PhoneAreaCode + "-" + personalInfoState.PhoneNumber}
                            </a>
                        </div>
                    </div>

                    {/* Email */}
                    <div className={styles.item_large}>
                        <div className={styles.text}>
                            <img src={ic_email}/>
                            <span>{uiTitles.email_title}</span>
                        </div>
                        <div className={styles.value}>
                            <a href={`mailto:${personalInfoState.EmailAddress}`}>
                                {personalInfoState.EmailAddress}
                            </a>
                        </div>
                    </div>

                    {/* Wechat */}
                    <div className={styles.item_large}>
                        <div className={styles.text}>
                            <img src={ic_wechat}/>
                            <span>{uiTitles.wechat_title}</span>
                        </div>
                        <div className={styles.value}>
                            <span>{personalInfoState.WeChatID}</span>
                        </div>
                    </div>

                    {/* Linkin*/}
                    <div className={styles.item_large}>
                        <div className={styles.text}>
                            <img src={ic_linkin}/>
                            <span>{uiTitles.linkin_title}</span>
                        </div>
                        <div className={styles.value}>
                            <span>{personalInfoState.LinkInAccount}</span>
                        </div>
                    </div>

                    {/* Official Site */}
                    <div className={styles.item_large}>
                        <div className={styles.text}>
                            <img src={ic_network}/>
                            <span>{uiTitles.official_site_title}</span>
                        </div>
                        <div className={styles.value}>
                            <a href={"https://www.cupower.com"}>www.cupower.com</a>
                        </div>
                    </div>

                    {/* Address */}
                    <div className={styles.item_large}>
                        <div className={styles.text}>
                            <img src={ic_address}/>
                            <span>{uiTitles.address_title}</span>
                        </div>
                        <div className={styles.value}>
                        <span className={styles.small_span}>
                            {personalInfoState.CompanyAddress}
                        </span>
                        </div>
                    </div>

                    {/* QRCode and Title*/}
                    <div className={styles.qrcode_and_title}>
                        <div className={styles.qr_img}>
                            <img src={ic_official_site_qr_code}/>
                        </div>

                        <div className={styles.title}>
                            <span className={styles.title}>{uiTitles.born_to_be_light_title}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom Buttons Group */}
            <div className={styles.bottom_control}>
                <button className={styles.btn} onClick={shareByPicture}>
                    <img src={ic_share} width={28}/>
                    <span className={styles.text}>{uiTitles.share_by_picture_title}</span>
                </button>
                <div className={styles.split_line}/>
                <button className={styles.btn} onClick={DownloadToPhone}>
                    <img src={ic_download} width={28}/>
                    <span className={styles.text}>{uiTitles.download_to_contact_title}</span>
                </button>
            </div>

            {/* Language */}
            <CenterPopup
                style={{"--max-width": "calc(100vw - 32px)", "--border-radius": "16px"}}
                closeOnMaskClick={false}
                visible={showLangSelectPopup}>

                <div className={styles.lang_popup}>

                    <div className={styles.title}>
                        <img src={ic_logo}/>
                    </div>

                    <div className={styles.tips}>
                        <span>请选择语言开始</span>
                        <span>Please Choose a language to start</span>
                    </div>

                    <div className={styles.control}>
                        <button onClick={ChooseChinese}>
                            简体中文
                        </button>
                        <button onClick={ChooseEnglish}>
                            English
                        </button>
                    </div>
                </div>
            </CenterPopup>
        </div>
    );
}

export default App;
