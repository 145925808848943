import ic_rita from "./asserts/avatars/ic_rita.png"

const UserList = {
    list: [
        {
            name: "rita",
            en: {
                UserName: "Rita Wu",
                Avatar: ic_rita,
                OfficeLocation: "ASIA",
                OfficePositionName: "Sales Director",
                PhoneAreaCode: "+86",
                PhoneNumber: "15815536430",
                EmailAddress: "rita@cupower.com",
                WeChatID: "ritawu29",
                LinkInAccount: "Linkin_account",
                OfficialSiteAddress: "www.cupower.com",
                CompanyAddress: "Floor 2, Building E, Taohuayuan Smart & Innovation Park, 518102 Shenzhen, China "
            },
            cn: {
                UserName: "吴顺南",
                Avatar: ic_rita,
                OfficeLocation: "亚洲",
                OfficePositionName: "销售总监",
                PhoneAreaCode: "+86",
                PhoneNumber: "15815536430",
                EmailAddress: "rita@cupower.com",
                WeChatID: "ritawu29",
                LinkInAccount: "Linkin_account",
                OfficialSiteAddress: "www.cupower.com",
                CompanyAddress: "深圳市宝安区桃花源智创小镇E栋2楼"
            }
        },
    ],
}

export default UserList;
