import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    createHashRouter,
    HashRouter,
    Route,
    RouterProvider,
    Routes,
    useLocation
} from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const router = createHashRouter([
    {
        path: "/contacts/:userid",
        element: <App/>
    },
]);

root.render(
    <React.StrictMode>
        <HashRouter basename={"/contacts"}>
            <Routes>
                <Route path={"/:userid"} element={<App/>}/>
                {/*<Route path={"/contacts/:userid"} element={<App/>}/>*/}
            </Routes>
        </HashRouter>
    </React.StrictMode>
);

reportWebVitals();
